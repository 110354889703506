@font-face {
	font-family: 'TradeGothic';
	font-style: normal;
	font-weight: 400;
	src: local('Trade Gothic'),
		url('./Fonts/TradeGothicNextLTW05-Rg.woff2') format('woff2'),
		url('./Fonts/TradeGothicNextLTW05-Rg.woff') format('woff');
}

@font-face {
	font-family: 'TradeGothic';
	font-style: normal;
	font-weight: 700;
	src: local('Trade Gothic'),
		url('./Fonts/TradeGothicNextLTW05-Bold.woff2') format('woff2'),
		url('./Fonts/TradeGothicNextLTW05-Bold.woff') format('woff');
}

@font-face {
	font-family: 'TradeGothicCond';
	font-style: normal;
	font-weight: 400;
	src: url('./Fonts/TradeGothicNextLTW05-Cond.woff2') format('woff2'), 
		url('./Fonts/TradeGothicNextLTW05-Cond.woff') format('woff');
}

html {
	text-rendering: optimizeLegibility !important;
}

